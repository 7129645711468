import {
    chatStore,
    chatMessagesStream,
    chatCurrentUserInfoStream,
    getPreviousMessagesByUuid,
} from "../../Stores/ChatStore";
import type { MessageUserDirect, MessageJoinChat } from "../../Connexion/ConnexionModels";
import { GameScene } from "../../Phaser/Game/GameScene";

import React, { useState, useEffect } from "react";
import type { AddPlayerInterface } from "../../Phaser/Game/AddPlayerInterface";
const { Channel } = require("@julianograms/react-chat-elements");
import { localUserStore } from "../../Connexion/LocalUserStore";

/* eslint-disable-next-line */
const Wrapper = (props: any) => {
    const game = props.game;
    const [isOpen, setIsOpen] = useState(false);
    const [show, setShow] = useState(false);
    const [messages, setMessages] = useState<Array<any>>([]); /* eslint-disable-line */
    const [users, setUsers] = useState<Array<any>>([]); /* eslint-disable-line */
    const [groups, setGroups] = useState<Array<any>>([]); /* eslint-disable-line */
    const [selectedChannel, setSelectedChannel] = useState<any>(null); /* eslint-disable-line */
    const [newMessage, setNewMessage] = useState<any>(null); /* eslint-disable-line */

    function onChannelChangeChat() {
        chatStore.getStream().subscribe((joinedUser: MessageJoinChat | string) => {
            if (typeof joinedUser === "string") {
                /* eslint-disable-next-line */
                setUsers((teste: any) => {
                    return teste.filter((user: any) => user.uuid !== joinedUser); /* eslint-disable-line */
                });
                /* eslint-disable-next-line */
                setSelectedChannel((teste: any) => {
                    if (teste?.uuid === joinedUser) {
                        setMessages([]);
                        setIsOpen(false);
                        return null;
                    }
                    return teste;
                });
            } else {
                /* eslint-disable-next-line */
                setUsers((teste: any) => {
                    /* eslint-disable-next-line */
                    if (teste.every((user: any) => user.uuid !== joinedUser.uuid)) {
                        /* eslint-disable-line */
                        teste.push({ ...joinedUser, avatar: "static/images/no_avatar.png", unread: 0, messages: [] });
                    }
                    return [...teste];
                });
            }
        });
    }

    function onMessageReceived() {
        chatMessagesStream.getStream().subscribe((event) => {
            setNewMessage(event);
        });
    }

    function onUserInfoChanged() {
        chatCurrentUserInfoStream.getStream().subscribe((a) => {
            setGroups(() => {
                /* eslint-disable-next-line */
                return a.groups.map((group: any) => {
                    return {
                        ...group,
                        messages: [],
                    };
                });
            });
        });
    }

    useEffect(() => {
        onChannelChangeChat();
        onMessageReceived();
        onUserInfoChanged();
    }, []);

    useEffect(() => {
        if (newMessage) {
            const localUser = localUserStore.getLocalUser();
            for (const scene of game.scene.getScenes(true)) {
                if (scene instanceof GameScene) {
                    scene.sound.play("audio-chat-message-notification", {
                        volume: 1,
                    });
                }
            }
            let messages = [];
            const shouldNotify = !(selectedChannel && selectedChannel.uuid === newMessage.to);
            if (
                selectedChannel &&
                (selectedChannel.uuid === newMessage.to || selectedChannel.uuid === newMessage.sender)
            ) {
                messages = selectedChannel.messages;
                setSelectedChannel({ ...selectedChannel, messages: messages });
            }
            messages.push({
                time: new Date(newMessage.date),
                type: "text",
                title: newMessage.name,
                author: "them",
                text: newMessage.text,
                avatar: "static/images/no_avatar.png",
            });

            const onlineChatUser = users.find(
                /* eslint-disable-next-line */
                (user: any) => user.uuid === newMessage.sender && newMessage.to === localUser?.uuid
            );
            if (onlineChatUser) {
                onlineChatUser.messages = messages;

                if (shouldNotify) {
                    if (!isNaN(onlineChatUser.unread)) onlineChatUser.unread++;
                    else onlineChatUser.unread = 1;
                }

                setUsers([...users]);
            } else {
                /* eslint-disable-next-line */
                const group = groups.find((group: any) => group.uuid === newMessage.to);
                if (group) {
                    group.messages = messages;
                    if (shouldNotify) {
                        if (!isNaN(group.unread)) group.unread++;
                        else group.unread = 1;
                    }

                    setGroups([...groups]);
                }
            }
        }
    }, [newMessage]);
    /* eslint-disable-next-line */
    function onSendMessage(item: any) {
        /* eslint-disable-next-line */
        setSelectedChannel((channel: any) => {
            if (channel && item) {
                channel.messages.push({
                    ...item,
                    // position: message.author === "me" ? "right" : "left",
                    type: "text",
                    text: item.data.text,
                    // deletable: false,
                    date: item.time,
                    title: "Eu",
                });

                const userMessage: MessageUserDirect = {
                    userId: channel?.userId,
                    text: item.data.text,
                    uuid: channel?.uuid,
                    date: new Date(),
                    identifiers: channel.identifiers,
                };
                console.log(userMessage);
                for (const scene of game.scene.getScenes(true)) {
                    if (scene instanceof GameScene) {
                        scene.sendDirectMessage(userMessage);
                    }
                }
                const listUser = channel.identifiers
                    ? /* eslint-disable-next-line */
                      groups.find((group: any) => group.uuid === channel.uuid)
                    : /* eslint-disable-next-line */
                      users.find((user: any) => user.uuid === channel.uuid);
                listUser.messages = channel.messages;
                setUsers([...users]);
            }
            return { ...channel };
        });
    }

    return (
        <Channel
            users={users}
            messages={selectedChannel?.messages || []}
            show={show}
            toggleFloatButton={() => {
                for (const scene of game.scene.getScenes(true)) {
                    if (scene instanceof GameScene) {
                        if (!show) {
                            scene.userInputManager.disableControls();
                        } else {
                            setSelectedChannel(null);
                            setIsOpen(false);
                            scene.userInputManager.restoreControls();
                        }
                    }
                }
                setShow(!show);
            }}
            alertCount={
                users.reduce((acc, user) => (user.unread || 0) + acc, 0) +
                groups.reduce((acc, group) => (group.unread || 0) + acc, 0)
            }
            onClose={() => {
                setIsOpen(false);
                setSelectedChannel(null);
            }}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            groups={groups}
            selectedChannel={selectedChannel}
            onSendMessage={onSendMessage}
            /* eslint-disable-next-line */
            onChannelChange={async (item: any, type: string) => {
                const token = localUserStore.getAuthToken();
                const localUser = localUserStore.getLocalUser();
                if (token) {
                    const previousMessages = await getPreviousMessagesByUuid(token, item.uuid, type);
                    /* eslint-disable-next-line */
                    item.messages = previousMessages.map((pm: any) => {
                        const isMe = pm.senderUuid === localUser?.uuid;
                        return {
                            time: new Date(pm.date),
                            type: "text",
                            title: isMe ? "Eu" : pm.name ? pm.name : item.name,
                            author: isMe ? "me" : "them",
                            text: pm.text,
                            avatar: "static/images/no_avatar.png",
                        };
                    });
                }

                setSelectedChannel(() => {
                    item.unread = 0;
                    /* eslint-disable-next-line */
                    if (type === "group") {
                        setGroups((groups) => {
                            const user = groups.find(
                                /* eslint-disable-next-line */
                                (group: any) => group.uuid === item.uuid
                            );
                            user.unread = 0;
                            return [...groups];
                        });
                    } else {
                        setUsers((users) => {
                            const user = users.find(
                                /* eslint-disable-next-line */
                                (user: any) => user.userId === item.userId
                            );
                            user.unread = 0;
                            return [...users];
                        });
                    }

                    return item;
                });
                setIsOpen(() => true);
            }}
        />
    );
};

export default Wrapper;
